import React from 'react'
import TeamDash from '../features/team/TeamDash'
import TeamCardRow from '../features/team/TeamCardRow'


function Team() {
  return (
    <>
    <TeamDash />
    <TeamCardRow />
    </>
  )
}

export default Team