import Dash1 from '../features/aboutUs/Dash1';

import DashImage from '../features/aboutUs/DashImage';

function AboutUs() {
return(
  <div id="aboutus">
  <Dash1 />
  <DashImage style={{ margin: '20px 0' }} />
  
  
  </div>
)
}

export default AboutUs;
